.brands-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px !important;
    font-weight: 700 !important;
    color: var(--black1) !important;
    margin-top: 50px !important;
    margin-bottom: 20px !important;
    padding-left: 100px !important;
}

.brands-color {
    border-left: solid black 1px;
    background-color: var(--pastel-teal);
}

.brands-category {
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: solid black 3px;
    border-top: solid black 3px;
}

.brands-category .brands-row {
    margin-left: 200px;
    margin-right: 200px;
}

.brands-category .brands-row .brands-btn {
    width: 100%;
    height: 15vh;
    border-radius: 15px;
    font-size: 28px;
    font-family: 'Cabin', sans-serif;
    border: none;
}

.bbtn-1 {
    background: url(../../media/c1.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-2 {
    background: url(../../media/c2.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-3 {
    background: url(../../media/c3.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-4 {
    background: url(../../media/c4.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-5 {
    background: url(../../media/c5.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-6 {
    background: url(../../media/c6.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-7 {
    background: url(../../media/c7.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.bbtn-8 {
    background: url(../../media/c8.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: black solid 2px;
}

.brands-all {
    padding-bottom: 100px;
    background-image: linear-gradient(white, var(--pastel-yellow));
}

.brands-all-row {
    margin-left: 100px !important;
    margin-right: 100px !important;
    margin-bottom: 50px !important;
}

.brands-all-row .brands-all-col .brands-all-card {
    border: none;
    border-radius: 0%;
    align-items: end;
    background-color: transparent;
}
.brands-all-img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.brands-all-row .brands-all-col .brands-all-card .brands-all-img{
    border-radius: 0%;
}

.brands-all-row .brands-all-col .brands-all-card .brands-all-content{
    display: flex;
    align-items: flex-end;
    font-family: 'Cabin', sans-serif;
}
.brands-all-card{
    max-width: 256px;
    width: 100%;
    height: 256px !important;
}
.brands-all-content-gradient{
    width: 100%;
    height: 94px;
    flex-shrink: 0;
    bottom: 0 !important;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    top: inherit !important;
    display: flex;
    justify-content: flex-start;
    align-items: end;
}
.brands-all-content-name{
    color: var(--Background, #FEFEFE) !important;
font-family: Cabin !important;
font-size: 20px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
z-index: 1;
}