.messages-page h4 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .message-card {
    border-radius: 10px;
    background-color: #f7f7f7;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
  }
  
  .message-card p {
    font-size: 16px;
    font-weight: 400;
  }
  
  .message-card .blockquote-footer {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
  }
  