.resetPasswordContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6b73ff 0%, #000dff 100%);
  }
  
  .resetPasswordBox {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .resetPasswordHeading {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.8rem;
  }
  
  .resetPasswordForm {
    display: flex;
    flex-direction: column;
  }
  
  .inputContainer {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .inputContainer input {
    width: 100%;
    padding: 0.8rem 2.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .inputContainer input:focus {
    border-color: #000dff;
    box-shadow: 0 0 8px rgba(0, 13, 255, 0.2);
  }
  
  .inputContainer svg {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    color: #000dff;
  }
  
  .resetPasswordBtn {
    padding: 0.8rem;
    background: #000dff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .resetPasswordBtn:hover {
    background: #6b73ff;
  }
  