.cart {
    padding: 80px;
}

.cart .cart-h {
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}

.cart .cart-row .cart-col-1 .cart-delivery,
.cart .cart-row .cart-col-1 .cart-items,
.cart .cart-row .cart-col-1 .cart-items .cart-item {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 25px;
    font-family: 'Cabin', sans-serif;
    margin-bottom: 20px;
}

.cart .cart-row .cart-col-1 .cart-delivery .cart-address .cart-address-text {
    color: #737373;
    font-size: 14px;
}

.cart .cart-row .cart-col-1 .cart-delivery .cart-delivery-controls .cart-edit,
.cart .cart-row .cart-col-1 .cart-delivery .cart-delivery-controls .cart-change {
    background-color: transparent;
    color: #000000;
    border: none;
    margin-left: 6px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-img-container .cart-item-img {
    width: 100%;
    height: auto;
    padding: 20px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-text-h {
    margin-top: 10px;
    font-size: 24px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-text-p {
    color: #737373;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-btn-promo {
    background-color: transparent;
    color: #737373;
    font-size: 14px;
    text-decoration: underline;
    border: none;
    padding: 0;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price {
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price .cart-item-og-price {
    text-decoration: line-through;
    color: #747474;
    margin-right: 8px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price .cart-item-sub {
    background-color: #3f3f3f;
    color: #ffffff;
    border: none;
    border-radius: 20px 0px 0px 20px;
    padding: 2px 20px;
    margin-left: 50px;
    margin-right: 15px;
}

.cart-item-sub {
    background-color: #3f3f3f;
    color: #ffffff;
    border: none;
    border-radius: 20px 0px 0px 20px;
    padding: 2px 20px;
    margin-left: 50px;
    margin-right: 15px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price .cart-item-add {
    background-color: #3f3f3f;
    color: #ffffff;
    border: none;
    border-radius: 0px 20px 20px 0px;
    padding: 2px 20px;
    margin-left: 15px;
}

.cart-item-add {
    background-color: #3f3f3f;
    color: #ffffff;
    border: none;
    border-radius: 0px 20px 20px 0px;
    padding: 2px 20px;
    margin-left: 15px;
}

.cart .cart-row .cart-col-2 {
    font-family: "Inter", sans-serif;
    padding: 0px 40px;
}

.cart .cart-row .cart-col-2 .cart-delivery-text {
    font-size: 20px;
}

.cart .cart-row .cart-col-2 .cart-promo-text {
    font-size: 12px;
}

.cart .cart-row .cart-col-2 .cart-promo-box {
    border: 1px solid black;
    margin-bottom: 20px;
}

.cart .cart-row .cart-col-2 .cart-promo-btn {
    background-color: transparent;
    color: #000000;
    height: 100%;
    border: #000000 1px solid;
}

.cart .cart-row .cart-col-2 .cart-promo-box::placeholder {
    color: #8f8f8f;
}

.cart .cart-row .cart-col-2 .cart-price {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 25px;
}

.cart .cart-row .cart-col-2 .cart-price .cart-price-text {
    font-size: 20px;
    margin-bottom: 5px;
}

.cart .cart-row .cart-col-2 .cart-price .cart-price-right {
    float: right;
}

.cart .cart-row .cart-col-2 .cart-price .cart-btn {
    width: 100%;
    background-color: var(--black1);
    margin-top: 25px;
    border-radius: 4px;
    border: none;
}

.cart .cart-empty .cart-h {
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}

.cart .cart-empty .cart-empty-container {
    font-family: 'Cabin', sans-serif;
    background-color: #f6f6f6;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 100px 0px;
}

.cart .cart-empty .cart-empty-container .cart-empty-h {
    font-size: 24px;
    margin-top: 20px;
}

.cart .cart-empty .cart-empty-container .cart-empty-p {
    font-size: 16px;
}

.cart .cart-empty .cart-empty-container .cart-empty-btn {
    background-color: var(--black1);
    color: #ffffff;
    border-radius: 32px;
    margin-top: 10px;
    padding: 8px 35px;
    border: none;
}

.bold {
    font-weight: bolder;
}

.green {
    color: #6A9B00;
}

.custom-hr-1 {
    margin: 10px 0;
    border-top: #000000 1px solid;
    opacity: 1;
}

.cart-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust this value if necessary */
}

.item-amount {
    width: 10%;
    display: flex;
    height: 30px;
}

.order-confirmation {
    padding: 20px;
    background-color: #F4ECC8;
    font-family: Arial, sans-serif;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1152px;
    width: 100%;
    min-height: 780px;
    flex-shrink: 0;
    justify-content: space-around;

}

.confirmation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4ecc4;
    border-radius: 8px;
    color: #000;
    text-align: center;

    font-family: Cabin;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 80%;
}

.confirmation-header h2 {
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Cabin;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 53px;
    margin-bottom: 23px;
}

.track-order-btn {
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 32px;
    background: var(--Button-normal, #0C2D1A);
    display: flex;
    width: 162px;
    height: 40px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

}

.confirmation-card {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    border: 1px solid #C6C6C6;
    background: #FFFEF9;
    max-width: 1018px;
    width: 100%;
    flex-shrink: 0;

}

.order-item-details {
    display: flex;
    align-items: center;
}

.order-item-img-container {
    text-align: center;
}

.order-item-img {
    max-width: 150px;
    border-radius: 8px;
    border-radius: 8px;
    border: 1px solid #C6C6C6;
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    object-fit: cover;
}

.order-item-text {
    padding-left: 20px;
}

.order-item-text p {
    color: #747474;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

.order-item-text h4 {
    color: #242424;
    leading-trim: both;
    text-edge: cap;
    font-family: Cabin;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.order-details {
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1050px;
    width: 100%;
    min-height: 240px;
    flex-shrink: 0;
}

.order-details h4 {
    margin-bottom: 20px;
}

.order-detail-section {
    display: flex;
    width: 269px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.order-detail-section p {
    color: #747474;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

.order-detail-section h5 {
    color: #0C2D1A;
    font-family: Cabin;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

.price {
    color: #242424 !important;
    font-family: Inter !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.oderdetailrow {
    border-radius: 16px;
    border: 1px solid #C6C6C6;
    background: #FFFEF9;
    max-width: 1050px;
    width: 100%;
    height: 240px;
    flex-shrink: 0;
    margin-bottom: 66px;
    padding: 20px;
    display: inline-flex;
    align-items: center;
    /* gap: 50px; */

}

.cartitem-varient {
    color: #747474;
    leading-trim: both;
    text-edge: cap;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.order-processing-modal-body {
    text-align: center;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;

}

.order-processing-image {
    width: 100%;
    height: 266px;
    margin-bottom: 10px;
    object-fit: cover;
    opacity: 0.7;

}

.order-processing-text {
    font-size: 18px;

    position: absolute;
}

.modal-content {
    background: transparent;
    outline: none !important;
    border: none !important;

}

.track-order-btn {
    border-radius: 32px !important;
    background: var(--Button-normal, #0C2D1A) !important;
    display: flex !important;
    width: 162px !important;
    height: 40px !important;
    padding: 10px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    flex-shrink: 0 !important;
}

.img-confirm {
    margin-bottom: 12px;
}

.single-order {
    border-radius: 16px;
    border: 2px solid #C6C6C6;
    background: #FFFEF9;
    max-width: 1050px;
    width: 100%;
    min-height: 228px;
    flex-shrink: 0;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;


}

.confirmation-card-multiple {
    border: none !important;
    width: 90%;
}

.confirmation-card-multiple:nth-last-child(1) {
    border-radius: 16px;
}

.single-pro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.order-item-img-multiple {
    border-radius: 8px;
    border: 1px solid #C6C6C6;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.track-order-btn-multiple {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 33px;
}