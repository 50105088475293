.categories .categories-top,
.all .categories-top{
    border-bottom: solid #000000 3px;
}

.categories .categories-top .categories-h,
.all .categories-top .categories-h  {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: var(--black1);
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 100px;
}

.categories .categories-top .categories-color, 
.all .categories-top .categories-color{
    border-left: solid black 1px;
    background-color: var(--pastel-teal);
}
.categories .categories-top .categories-filter,
.all .categories-top .categories-filter{
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}
.categories .categories-top .categories-filter .categories-top-btn,
.all .categories-top .categories-filter .categories-top-btn{
    background-color: var(--black2);
    color: #ffffff;
    border-radius: 8px;
    padding: 12px 20px;
    justify-content: center;
    border: none;
}
.categories .categories-products .categories-products-row {
    margin-top: 80px;
    margin-bottom: 80px;
}

.categories .categories-products .categories-products-row .categories-products-content {
    color: #000000;
    padding-left: 150px;
    padding-right: 50px;
}

.categories .categories-products .categories-products-row .categories-products-content .categories-products-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: -5px;
}

.categories .categories-products .categories-products-row .categories-products-content .categories-products-p {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
}

.categories .categories-products .categories-products-row .categories-products-content .categories-products-ctrl .custom-left-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 15px;
    color: #000000;
}

.categories .categories-products .categories-products-row .categories-products-content .categories-products-ctrl .custom-right-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-left: 15px;
    color: #000000;
}

.categories .categories-products .categories-products-row .categories-products-content .categories-products-btn {
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
    margin-top: 100px;
    margin-bottom: 50px;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card {
    background-color: transparent;
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 50px;
    font-family: 'Cabin', sans-serif;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-banner {
    background-color: #00000060;
    margin-bottom: -50px;
    z-index: 999;
    padding: 10px 5px;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-banner .categories-products-banner-img {
    width: 30px;
    height: auto;
    clip-path: circle(50% at 50% 50%);
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-banner .overlap-2 {
    margin-right: -15px;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-banner .overlap-1 {
    margin-right: -15px;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-price {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: -5px;
    font-weight: 700;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-title {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
}

.categories .categories-products .categories-products-row .categories-products-carousel-col .categories-products-carousel .categories-products-card .categories-products-desc {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 13px;
}

.categories-modal{
    font-family: 'Cabin', sans-serif;
}

.categories-modal .categories-modal-header .categories-modal-title{
    margin-left: 42%;
}

.categories-modal .categories-modal-row{
    margin-left: 5px;
    margin-right: 5px;
}

.categories-modal .categories-modal-row .categories-modal-col {
    border: #000000 solid 1px;
    border-radius: 10px;
    margin: 5px;
}

.categories-modal .categories-modal-row .categories-modal-col .categories-modal-select{
    border: none;
}

.categories-modal .categories-modal-check .categories-modal-check-col{
    border: #000000 solid 1px;
    border-radius: 10px;
    padding: 15px;
    margin: 20px;
    border: #000000 solid 1px;
    border-radius: 10px;
    padding: 15px;
    margin: 20px;
    max-height: 266px;
    overflow: auto;
}

.categories-modal .categories-modal-check .categories-modal-check-h .categories-modal-check-btn{
    float: right;
    background-color: transparent;
    border: none;
    color: #000000;
    margin: 0%;
    padding: 0%;
}

.categories-modal .categories-modal-row .categories-modal-col-range{
    border: #000000 solid 1px;
    border-radius: 10px;
    margin: 5px;
    padding: 15px;
}

.categories-modal .categories-modal-row .categories-modal-col-range .categories-modal-range-end{
    float: right;
    font-size: 12px;
}

.categories-modal .categories-modal-row .categories-modal-col-range .categories-modal-range-start{
    float: left;
    font-size: 12px;
}

.categories-modal .categories-modal-footer {
    justify-content: center;
}

.categories-modal .categories-modal-footer .categories-modal-btn{
    background-color: var(--black2);
    border: none;
    padding: 5px 45px;
}


.all .all-items{
    background-color: #DEF4C8;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 80px;
}

.all .all-items .all-items-h{
    padding-top: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    margin-bottom: -5px;
}

.all .all-items .all-items-p{
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
}

.all .all-items .all-items-row .categories-products-card {
    background-color: transparent;
    border: none;
    margin-top: 50px;
    margin-right: 50px;
    font-family: 'Cabin', sans-serif;
}
.categories-products-card{
    max-width: 260px !important;

}
.all .all-items .all-items-row{
    margin-left: 50px;
    margin-right: 50px;
}

.all .all-items .all-items-row .categories-products-card .categories-products-banner {
    background-color: #00000060;
    margin-bottom: -40px;
    z-index: 999;
    padding: 10px 2px;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    font-size: 13px;
}

.all .all-items .all-items-row .categories-products-card .categories-products-banner .categories-products-banner-img {
    width: 20px;
    height: auto;
    clip-path: circle(50% at 50% 50%);
}

.all .all-items .all-items-row .categories-products-card .categories-products-banner .overlap-2 {
    margin-right: -15px;
}

.all .all-items .all-items-row .categories-products-card .categories-products-banner .overlap-1 {
    margin-right: -15px;
}

.all .all-items .all-items-row .categories-products-card .categories-products-price {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: -5px;
    font-weight: 700;
}

.all .all-items .all-items-row .categories-products-card .categories-products-title {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
}

.all .all-items .all-items-row .categories-products-card .categories-products-desc {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 13px;
}
a {
    text-decoration: none !important;
    color: #ffffff;
}
.categories-products-card-img{
    max-width: 260px;
    width: 100%;
    height: 288px;
    object-fit: cover;
}
.bg-blue{
    background: #D8EDF9;
}
.bg-yellow{
    background: #F4ECC8;
}
