.terms{
    padding-top: 60px;
    padding-bottom: 60px;
}
.terms .terms-h{
    font-family: 'Russo One', sans-serif;
    font-size: 32px;
    text-align: center;
}
.terms .term{
    text-align: center;
    margin: 5px 170px;
    padding: 10px 40px;
}
.terms .term .term-h{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    margin-bottom: 15px;
}
.terms .term .term-p{
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}

.privacy{
    padding-top: 60px;
    padding-bottom: 60px;
}
.privacy .privacy-h{
    font-family: 'Russo One', sans-serif;
    font-size: 32px;
    text-align: center;
}
.privacy .policy{
    text-align: center;
    margin: 5px 170px;
    padding: 10px 40px;
}
.privacy .policy .policy-h{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    margin-bottom: 15px;
}
.privacy .policy .policy-p{
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}

.us-img{
    max-height: 350px;
    width: auto;
}
.us-h{
    margin-top: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 48px;
}
.us-p{
    font-size: 24px;
    font-family: 'Cabin', sans-serif;
    width: 75%;
}
.experience{
    background-color: var(--pastel-green);
    padding: 90px 150px;
}
.experience-h{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 48px;
    margin-bottom: 30px;
}
.experience-p{
    font-size: 24px;
    font-family: 'Cabin', sans-serif;
}
.instasales-img{
    max-height: 350px;
    width: auto;
    float: right;
}
.instasales-h{
    margin-top: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 48px;
    margin-left: 80px;
}
.instasales-p{
    font-size: 24px;
    font-family: 'Cabin', sans-serif;
    margin-left: 80px;
}
.faqs{
    background: url(../../media/ap3.PNG);
    padding: 80px 400px 200px 400px;
    text-align: center;
}
.faqs-h{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 64px;
    margin-bottom: 50px;
}
.faqs .accordion{
    --bs-accordion-bg: transparent;
    --bs-accordion-active-color: #000000;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-border-width: 0px;
}
.faqs .accordion .accordion-item .accordion-header{
    font-size: 18px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.faqs .accordion .accordion-item .accordion-body{
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
    text-align: left;
}

.custom-hr-1{
    margin: 10px 0;
    border-top: #000000 1px solid;
    opacity: 1;
}

.creator-partnership .cp-top{
    position: relative;
    height: 500px;
    background-color: var(--pastel-green);
}
.creator-partnership .cp-top .cp-top-img1{
    position: absolute;
    z-index: 0;
    bottom: 0;
}
.creator-partnership .cp-top .cp-top-content{
    text-align: center;
    padding-left: 350px;
    padding-right: 350px;
}
.creator-partnership .cp-top .cp-top-content .cp-top-h{
    font-size: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    padding: 70px 90px 40px 90px;
    line-height: 1.2;
}
.creator-partnership .cp-top .cp-top-content .cp-top-p{
    margin-left: 80px;
    margin-right: 80px;
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}
.creator-partnership .cp-top .cp-top-img2{
    position: absolute;
    right: 0;
    z-index: 0;
    bottom: 0;
}
.creator-partnership .cp-top .cp-top-btn{
    background-color: var(--black1);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 32px;
    margin: 30px;
}
.creator-partnership .cp-benefits{
    border-top: #000000 solid 3px;
    border-bottom: #000000 solid 3px;
    padding: 70px;
    position: relative;
}
.creator-partnership .cp-benefits .cp-benefits-row .cp-benefits-h{
    font-size: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    padding-right: 200px;
    padding-top: 30px;
}
.creator-partnership .cp-benefits .cp-benefits-row .cp-benefits-txt{
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
    padding-right: 200px;
}
.creator-partnership .cp-benefits .cp-benefits-circle{
    position: absolute;
    z-index: -1;
    background-color: var(--pastel-blue);
    width: 300px;
    height: 300px;
    left: -80px;
    top: 250px;
    clip-path: circle(50%);
}
.creator-partnership .cp-creators-word{
    background-color: var(--pastel-yellow);
    padding: 50px;
    border-bottom: #000000 solid 3px;
}
.creator-partnership .cp-creators-word .cp-creators-word-h{
    font-size: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: center;
    margin-bottom: 20px;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item{
    padding: 50px 180px;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-quotes{
    color: #3f3f3f7d;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-quotes .cp-carousel-quote-left{
    margin-top: -50px;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-quotes .cp-carousel-quote-right{
    margin-top: 150%;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-img-wrap{
    margin: auto;
    text-align: center;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-img-wrap .cp-carousel-img{
    width: 120px;
    height: auto;
    clip-path: circle(50%);
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-txt{
    margin-top: auto;
    margin-bottom: auto;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-txt .cp-carousel-dark{
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}
.creator-partnership .cp-creators-word .cp-carousel .cp-carousel-item .cp-carousel-row .cp-carousel-txt .cp-carousel-light{
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
    color: #000000c4;
}
.creator-partnership .cp-join{
    display: flex;
    flex-direction: row;
    padding-top: 50px;
}
.creator-partnership .cp-join .cp-join-img{
    display: flex;
    flex-direction: column;
    padding-top: 100px;
}
.creator-partnership .cp-join .cp-join-form{
    display: flex;
    flex-direction: column;
    width: 65%;
}
.creator-partnership .cp-join .cp-join-form .cp-join-h{
    font-size: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.creator-partnership .cp-join .cp-join-form .input-group-text{
    background-color: transparent;
}
.creator-partnership .cp-join .cp-join-form .cp-join-row .cp-join-col .cp-join-input-group{
    border: #000000 solid 1px;
    border-radius: 5px;
    margin-top: 20px;
}
.creator-partnership .cp-join .cp-join-form .cp-join-row .cp-join-col .cp-join-input-group .input-group-text{
    border: none;
}
.creator-partnership .cp-join .cp-join-form .cp-join-row .cp-join-col .cp-join-input-group .form-control{
    border-right: none;
    border-bottom: none;
    border-top: none;
    border-left: #000000 solid 1px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.creator-partnership .cp-join .cp-join-form .cp-join-row .input-group{
    margin-top: 20px;
}
.creator-partnership .cp-join .cp-join-form .cp-join-row .input-group-text{
    border: #000000 solid 1px;
}
.creator-partnership .cp-join .cp-join-form .cp-join-row .form-control{
    border: #000000 solid 1px;
    border-radius: 5px;
}
.creator-partnership .cp-join .cp-join-form .cp-join-btn{
    background-color: var(--black1);
    color: #ffffff;
    border: none;
    border-radius: 35px;
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 70px;
}

.brand-collab .bc-top{
    background: url(../../media/bc1.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    background-color: var(--pastel-green);
}
.brand-collab .bc-top .bc-top-content{
    text-align: center;
    padding-left: 350px;
    padding-right: 350px;
}
.brand-collab .bc-top .bc-top-content .bc-top-h{
    font-size: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    padding: 100px 130px 40px 130px;
    line-height: 1.2;
}
.brand-collab .bc-top .bc-top-content .bc-top-p{
    margin-left: 80px;
    margin-right: 80px;
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}
.brand-collab .bc-benefits{
    border-top: #000000 solid 3px;
    border-bottom: #000000 solid 3px;
    padding: 70px;
    position: relative;
}
.brand-collab .bc-benefits .bc-benefits-row .bc-benefits-h{
    font-size: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    padding-right: 200px;
    padding-top: 30px;
}
.brand-collab .bc-benefits .bc-benefits-row .bc-benefits-txt{
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
    padding-right: 200px;
}
.brand-collab .bc-benefits .bc-benefits-circle{
    position: absolute;
    z-index: -1;
    background-color: var(--pastel-blue);
    width: 300px;
    height: 300px;
    left: -80px;
    top: 250px;
    clip-path: circle(50%);
}
.brand-collab .bc-creators-word{
    background-color: var(--pastel-yellow);
    padding: 50px;
    border-bottom: #000000 solid 3px;
}
.brand-collab .bc-creators-word .bc-creators-word-h{
    font-size: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: center;
    margin-bottom: 20px;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item{
    padding: 50px 180px;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-quotes{
    color: #3f3f3f7d;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-quotes .bc-carousel-quote-left{
    margin-top: -50px;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-quotes .bc-carousel-quote-right{
    margin-top: 150%;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-img-wrap{
    margin: auto;
    text-align: center;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-img-wrap .bc-carousel-img{
    width: 120px;
    height: auto;
    clip-path: circle(50%);
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-txt{
    margin-top: auto;
    margin-bottom: auto;
}
.brand-collab .bc-creators-word .bc-carousel .bc-carousel-item .bc-carousel-row .bc-carousel-txt .bc-carousel-dark{
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}
.brand-collab .bc-join{
    background: url(../../media/bc2.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
}
.brand-collab .bc-join .bc-join-form{
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}
.brand-collab .bc-join .bc-join-form .bc-join-h{
    font-size: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.brand-collab .bc-join .bc-join-form .input-group-text{
    background-color: transparent;
}
.brand-collab .bc-join .bc-join-form .bc-join-row .bc-join-col .bc-join-input-group{
    border: #000000 solid 1px;
    border-radius: 5px;
    margin-top: 20px;
}
.brand-collab .bc-join .bc-join-form .bc-join-row .bc-join-col .bc-join-input-group .input-group-text{
    border: none;
}
.brand-collab .bc-join .bc-join-form .bc-join-row .bc-join-col .bc-join-input-group .form-control{
    border-right: none;
    border-bottom: none;
    border-top: none;
    border-left: #000000 solid 1px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.brand-collab .bc-join .bc-join-form .bc-join-row .input-group{
    margin-top: 20px;
}
.brand-collab .bc-join .bc-join-form .bc-join-row .input-group-text{
    border: #000000 solid 1px;
}
.brand-collab .bc-join .bc-join-form .bc-join-row .form-control{
    border: #000000 solid 1px;
    border-radius: 5px;
}
.brand-collab .bc-join .bc-join-form .bc-join-btn{
    background-color: var(--black1);
    color: #ffffff;
    border: none;
    border-radius: 35px;
    width: 12%;
    margin-top: 70px;
    margin-bottom: 70px;
}

.bold{
    font-weight: bolder;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--cui-form-invalid-color);
    position: absolute;
    right: -14px;
    z-index: 9;
    top: 32px;

}