.creator2{
    background-color: var(--pastel-yellow);
}
.creator .creator-header{
    background: url(../../media/cb.png);
    text-align: right;
    padding: 100px 100px 50px 0px;
    position: relative;
}
.creator .creator-header .creator-btn1{
    background-color: var(--black2);
    color: #ffffff;
    border-radius: 8px;
    padding: 12px 20px;
    border: none;
}
.creator .creator-social{
    text-align: right;
    background-color: #242424;
    align-items: center;
    color: #ffffff;
    padding-left: 100px;
    padding-right: 100px;
}
.creator .creator-social .creator-btn2{
    background-color: #ffffff;
    border-radius: 32px;
    color: #000000;
    border: none;
    padding: 5px 50px;
    margin: 10px;
}
.creator .creator-social .creator-rd-start{
    margin-left: 450px;
}
.creator .creator-social .creator-social-rd{
    margin-bottom: -10px;
    margin-left: 15px;
    color: #ffffff;
}
.creator-card {
    position: absolute  !important;
    border: none !important;
    border-radius: 0% !important;
    background-color: transparent !important;
    top: 120px !important;
    left: 50px !important;
}
.creator-card .creator-card-img {
    border: solid 3px black;
    border-radius: 0%;
    width: 250px;
    /* margin-bottom: 20px; */
    object-fit: cover;
height: 250px;
background: white;
}

.creator-card .creator-card-txt {
    top: 65%;
    font-family: 'Cabin', sans-serif;
}

.creator-card .creator-card-txt .creator-card-h {
    font-size: large;
    margin-bottom: -5px;
    font-weight: bolder;
}

.creator-card .creator-card-txt .creator-card-p {
    font-size: medium;
}

.creator-card .creator-card-stats {
    position: absolute;
    bottom: -17px;
    right: -23px;
    background-color: black;
    padding: 20px;
    border: white solid 2px;
    border-radius: 100px;
    color: #FEFEFE;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 75px;
    height: 75px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.creator .creator-products-row {
    margin-top: 80px;
    margin-bottom: 80px;
    box-shadow: 0px 4px 80px 0px #0000000D;
}

.creator .creator-products-row .creator-products-content {
    color: #000000;
    padding-left: 150px;
    padding-right: 50px;
}

.creator .creator-products-row .creator-products-content .creator-products-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: -5px;
}

.creator .creator-products-row .creator-products-content .creator-products-p {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
}

.creator .creator-products-row .creator-products-content .creator-products-ctrl .custom-left-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 15px;
    color: #000000;
}

.creator .creator-products-row .creator-products-content .creator-products-ctrl .custom-right-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-left: 15px;
    color: #000000;
}

.creator .creator-products-row .creator-products-content .creator-products-btn {
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
    margin-top: 100px;
    margin-bottom: 50px;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card {
    background-color: transparent;
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 50px;
    font-family: 'Cabin', sans-serif;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-banner {
    background-color: #00000060;
    margin-bottom: -50px;
    z-index: 999;
    padding: 10px 5px;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-banner .creator-products-banner-img {
    width: 30px;
    height: auto;
    clip-path: circle(50% at 50% 50%);
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-banner .overlap-2 {
    margin-right: -15px;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-banner .overlap-1 {
    margin-right: -15px;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-price {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: -5px;
    font-weight: 700;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-title {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
}

.creator .creator-products-row .creator-products-carousel-col .creator-products-carousel .creator-products-card .creator-products-desc {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 13px;
}

.creator-modal{
    font-family: 'Cabin', sans-serif;
}

.creator-modal .creator-modal-header .creator-modal-title{
    margin-left: 42%;
}

.creator-modal .creator-modal-row{
    margin-left: 5px;
    margin-right: 5px;
}

.creator-modal .creator-modal-row .creator-modal-col {
    border: #000000 solid 1px;
    border-radius: 10px;
    margin: 5px;
}

.creator-modal .creator-modal-row .creator-modal-col .creator-modal-select{
    border: none;
}

.creator-modal .creator-modal-check .creator-modal-check-col{
    border: #000000 solid 1px;
    border-radius: 10px;
    padding: 15px;
    margin: 20px;
}

.creator-modal .creator-modal-check .creator-modal-check-h .creator-modal-check-btn{
    float: right;
    background-color: transparent;
    border: none;
    color: #000000;
    margin: 0%;
    padding: 0%;
}

.creator-modal .creator-modal-row .creator-modal-col-range{
    border: #000000 solid 1px;
    border-radius: 10px;
    margin: 5px;
    padding: 15px;
}

.creator-modal .creator-modal-row .creator-modal-col-range .creator-modal-range-end{
    float: right;
    font-size: 12px;
}

.creator-modal .creator-modal-row .creator-modal-col-range .creator-modal-range-start{
    float: left;
    font-size: 12px;
}

.creator-modal .creator-modal-footer {
    justify-content: center;
}

.creator-modal .creator-modal-footer .creator-modal-btn{
    background-color: var(--black2);
    border: none;
    padding: 5px 45px;
}

.creator-modal1 .creator-modal1-body{
    padding: 20px 60px;
    text-align: center;
}

.creator-modal1 .creator-modal1-body .creator-modal1-fc{
    border: #000000 solid 2px;
}

.creator-modal1 .creator-modal1-body .creator-modal1-btn{
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
    text-align: center;
    margin-top: 20px;
}
.creator-products-card-img{
    height: 285px;
    width: 100%;
}