/* manageProducts.css */

/* Manage Products Title */
.manage-products-title {
    font-size: 2rem;
    font-weight: 600;
    color: #4f4f4f;
}

/* Section Title */
.manage-products-section-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5rem;
    font-weight: 500;
}

/* Card Styling */
.manage-products-card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    transition: transform 0.2s;
}

.manage-products-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.manage-products-card-img {
    border-radius: 10px 10px 0 0;
    height: 180px;
    object-fit: cover;
}

.manage-products-card-body {
    padding: 15px;
    text-align: center;
}

.manage-products-card-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.manage-products-card-text {
    font-size: 1.2rem;
    color: #555;
}

.manage-products-card-text strike {
    font-size: 1rem;
    color: #999;
    margin-right: 10px;
}

.manage-products-card-text strong {
    font-size: 1.4rem;
    color: #000;
}

/* Button Styling */
.manage-products-btn-delete {
    font-size: 0.9rem;
    border-radius: 20px;
    color: #ff6b6b;
    border-color: #ff6b6b;
}

.manage-products-btn-delete:hover {
    background-color: #ff6b6b;
    color: white;
}

.manage-products-btn-favorite {
    font-size: 0.9rem;
    border-radius: 20px;
    color: #f1c40f;
    border-color: #f1c40f;
}

.manage-products-btn-favorite:hover {
    background-color: #f1c40f;
    color: white;
}

/* View More Products Button */
.manage-products-view-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 30px;
    border-color: #007bff;
}

.manage-products-view-more-btn:hover {
    background-color: #007bff;
    color: white;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .manage-products-card-img {
        height: 150px;
    }

    .manage-products-card-title {
        font-size: 0.9rem;
    }

    .manage-products-card-text strong {
        font-size: 1.2rem;
    }

    .manage-products-card-text strike {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .manage-products-card-title {
        font-size: 0.8rem;
    }

    .manage-products-card-text strong {
        font-size: 1.1rem;
    }

    
}