.feature-card{
    display: flex;
width: 220px;
height: 333px;
flex-direction: column;
justify-content: center;
/* align-items: center; */
flex-shrink: 0;
}
.feature-body{
    background: var(--Primary-Text, #242424);
    width: 220px;
flex-shrink: 0;
}
.feature-price{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.feature-title{
    color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: Cabin;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.feature-desc{
    color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: Cabin;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.feature-container{
    background: #E8F6FD;
}

/* Style the container holding the checkboxes */
.categories-modal-check-col .mb-3 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    padding: 10px;
}

/* Ensure that each checkbox has some spacing */
.categories-modal-check-col .form-check {
    margin-bottom: 0;
}

/* Optional: Styling the section title */
.categories-modal-check-h {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.categories-modal-check-txt {
    margin-left: 5px;
}
