.inventory-page {
    padding: 20px;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .dashboard-controls .btn {
    margin-left: 10px;
  }
  
  .profile-menu {
    background-color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
  }
  
  .inventory-card {
    border: none;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }
  
  .inventory-card img {
    max-width: 100px;
    border-radius: 10px;
  }
  
  .inventory-card h5 {
    font-size: 1.15rem;
    font-weight: 500;
  }
  
  .inventory-card .btn-dark {
    background-color: #343a40;
    border: none;
  }
  
  .inventory-card .btn-light {
    background-color: #f8f9fa;
    border: none;
    color: #6c757d;
  }
  
  .inventory-card .form-control {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
  }