.brands-all-content-gradient2{
    width: 100%;
    height: 94px;
    flex-shrink: 0;
    bottom: 0 !important;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    top: auto !important;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    flex-direction: column;
}