.creator-dashboard {
    padding: 20px;
  }
  
  .stat-card {
    background-color: #f8f9fa;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
  }
  
  .stat-card h5 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .stat-card p {
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  .dashboard-btn {
    padding: 10px 15px;
    font-size: 1.1rem;
  }
  
  .product-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-card img {
    max-height: 200px;
    object-fit: cover;
  }
  
  .product-card h5 {
    font-size: 1.1rem;
  }
  
  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .schedule-call-btn {
    padding: 10px 20px;
    font-size: 1.1rem;
  }
  