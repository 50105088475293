.profile {
    text-align: center;
    font-family: 'Cabin', sans-serif;
}

.profile .profile-bg {
    width: 100%;
    height: auto;
}

.profile .profile-pic {
    /* margin-top: -100px; */
    border-radius: 200px;
    border: solid 2px #000000;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.profile .profile-tabs-nav {
    justify-content: center;
    margin-top: 30px;
    border-bottom: #000000 1px solid;
    padding-bottom: 20px;
}

.profile .profile-tabs-nav .profile-tab .profile-tab-btn {
    background-color: var(--black1);
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
}

.profile .profile-tab-content {
    margin-bottom: 30px;
}

.profile .profile-tab-content .profile-h {
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile .profile-tab-content .profile-info-1 {
    padding-left: 200px;
    padding-right: 200px;
}

.profile .profile-tab-content .profile-input {
    background-color: white;
    border: #000000 solid 1px;
    border-radius: 8px;
    margin: 5px;
}

.profile .profile-tab-content .profile-input .input-group-text {
    border: none;
    background-color: transparent;
    margin-right: 5px;
}

.profile .profile-tab-content .profile-input .form-control,
.profile .profile-tab-content .profile-input .form-select {
    border-top: none;
    border-bottom: none;
    border-right: none;
}

.profile .profile-tab-content .profile-info-2 {
    padding-left: 400px;
    padding-right: 400px;
}

.profile .profile-btn {
    margin-top: 10px;
    background-color: var(--black1);
    color: #ffffff;
    padding: 5px 32px;
    border-radius: 20px;
    border: none;
}

.profile-search {
    width: 30% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    border: black solid 1px !important;
    height: 35px !important;
}

.profile-search .profile-search-input-box {
    height: 32px;
    font-size: small;
    background-color: transparent;
    border: none;
}

.profile-search .profile-search-button {
    height: 30px;
    background-color: transparent;
    border: none;
    padding: 0;
}

.profile-search .profile-search-button .profile-search-logo {
    background-color: black;
    padding: 6px;
    border: black solid 1px;
    border-radius: 40px;
    margin: 4px;
    color: #ffffff;
}

.profile-products {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.profile-products .profile-product {
    border: solid 1px #000000;
    border-radius: 10px;
    padding: 30px 0px;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    justify-content: center;
}

.profile-products .profile-product .profile-product-h {
    font-size: 24px;
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 400;
}

.profile-products .profile-product .profile-product-muted {
    text-align: left;
    color: var(--gray2);
    font-size: 16px;
    font-weight: 400;
}

.profile-products .profile-product .profile-product-price {
    font-size: 24px;
}

.profile-products .profile-product .profile-product-txt {
    text-align: left;
    font-size: 20px;
}

.profile-products .profile-product .profile-track {
    font-size: 16px;
    padding: 10px 32px;
    border: none;
    background-color: var(--black1);
    color: #ffffff;
    border-radius: 32px;
}

.profile-help-form {
    padding-left: 200px;
    padding-right: 200px;
}

.profile-help-form .profile-help-btn {
    font-size: 16px;
    padding: 10px 32px;
    border: none;
    background-color: var(--black1);
    color: #ffffff;
    border-radius: 32px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.profile-addresses {
    padding-left: 200px;
    padding-right: 200px;
}

.profile-addresses .profile-address {
    border: #000000 solid 1px;
    border-radius: 10px;
    margin: 10px;
    padding: 15px;
    width: 330px;
    height: auto;
    text-align: left;
    min-height: 210px;
}

.profile-addresses .profile-address .profile-addnew {
    margin-top: 25%;
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
}

.profile-addresses .profile-address .profile-action-items {
    margin-bottom: 15px;
}

.profile-addresses .profile-address .profile-action-items .profile-actions {
    float: right;
}

.profile-addresses .profile-address .profile-action-items .profile-actions .profile-edit {
    margin-right: 10px;
}

.profile-addresses .profile-address .profile-line2,
.profile-addresses .profile-address .profile-line3 {
    color: var(--gray2);
}

.profile-addresses .profile-address .profile-line3 {
    margin-bottom: 20px;
}

.profile-sub-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 300px;
    padding-right: 300px;
}

.profile-sub-cards .profile-sub-card {
    display: flex;
    flex-direction: row;
    border: #000000 solid 1px;
    border-radius: 10px;
    padding: 25px;
    margin: 20px;
    max-width: 402px;
    width: 100%;
}

.profile-sub-cards .profile-sub-card .profile-sub-img {
    width: 120px;
    height: auto;
    object-fit: cover;
cursor: pointer;
}

.profile-sub-cards .profile-sub-card .profile-sub-txt {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.profile-sub-cards .profile-sub-card .profile-sub-txt .profile-sub-h {
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
}

.profile-sub-cards .profile-sub-card .profile-sub-txt .profile-sub-subh {
    color: var(--gray2);
    line-height: 20px;
    margin-bottom: 10px;
}

.profile-sub-cards .profile-sub-card .profile-sub-txt .profile-sub-btn {
    background-color: #0C2D1A;
    color: #ffffff;
    padding: 5px 32px;
    border-radius: 32px;
    border: none;
}




.ta {
    width: 99.2%;
}

.upload-img {
    padding-right: 8px;
    padding-top: 8px;
    position: absolute;
    display: flex;
    right: 0;
    top: 8px;
    visibility: hidden;
    z-index: 2;
    cursor: pointer;
}

.profile-image-container:hover .profile-image-upload {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
}

.profile-image-container:hover {
    .upload-img {
        visibility: visible;
        opacity: 1;
    }
}

.profile-image-container {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-top: -100px;
}


.profile-image {
    max-width: 100%;
    transition: opacity 0.2s linear;
    /* Add opacity transition */
}

.profile-image-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    cursor: pointer;
    border-radius: 4px;
    background-color: #D9D9D9;
    display: inline-flex;
    padding: 5px 3px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FEFEFE;
    z-index: 2;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
}

.profile-image-container:hover .profile-image {
    opacity: 0.7;
    /* Decrease opacity on hover */
    cursor: pointer;

}

.profile-info {
    display: flex;
    width: 1120px;
    /* height: 166px; */
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    gap: 35px;
}

.profile-input2 {
    margin: 0px !important;
}

.tab-content>.active {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.profile-info>.col {
    display: flex;
    /* width: 286px; */
    padding: 27px 21px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid var(--Primary-Text, #242424);
    flex-direction: column;
    gap: 8px;
}

.profile-info>.col:nth-child(1)>.profile-input2 {
    border-radius: 4px;
    border: 1px solid #000;
    width: 244px;
    /* width: 100%; */
    flex-shrink: 0;
}

.profile-info>.col:nth-child(2)>.profile-input2 {
    border-radius: 4px;
    border: 1px solid #000;
    width: 423px;
    /* width: 100%; */
    flex-shrink: 0;
}

.profile-info>.col:nth-child(3)>.profile-input2 {
    border-radius: 4px;
border: 1px solid #000;
width: 243px;
flex-shrink: 0;
}

.profile-info>.col:nth-child(3) ,
.profile-info>.col:nth-child(1){
    max-width: 286px;
}
.ordered-item-img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.no-orders{
    border-radius: 8px;
background: #F9F9F9;
max-width: 934px;
width: 100%;
height: 124px;
flex-shrink: 0;
display: flex;
justify-content: center;
align-items: center;
color: #000;
font-family: Cabin;
font-size: 24px;
font-style: normal;
font-weight: 400;
margin-top: 30px;
line-height: normal;
}

.tracking-info-container {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px 0;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 90%;

}


.tracking-info-header {
    margin-bottom: 10px;
}

.tracking-info-price {
    font-size: 1.2em;
    font-weight: bold;
}

.tracking-info-address {
    margin-bottom: 20px;
}

.tracking-progress {
    margin: 20px 0;
}

.tracking-status-row {
    margin: 20px 0;
}

.tracking-dates {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.tracking-details {
    margin-top: 20px;
}

.tracking-scan-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.order-img{
    width: 100px !important;
height: 100px;
flex-shrink: 0;
display: contents;
border-radius: 8px;
border: 1px solid #000;
overflow: hidden;
}
/* .tracking-progress .progress-step {
    text-align: center;
    margin-top: 10px;
  }
  
  .progress-label {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
  }
   */

   .profile-product{
    position: relative;
   }
   .traking-btn{}