.cart {
    padding: 80px;
}

.cart .cart-h {
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}

.cart .cart-row .cart-col-1 .cart-delivery,
.cart .cart-row .cart-col-1 .cart-items,
.cart .cart-row .cart-col-1 .cart-items .cart-item {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 25px;
    font-family: 'Cabin', sans-serif;
    margin-bottom: 20px;
}

.cart .cart-row .cart-col-1 .cart-delivery .cart-address .cart-address-text {
    color: #737373;
    font-size: 14px;
}

.cart-address-text {
    color: #737373;
    font-size: 14px;
}

.cart .cart-row .cart-col-1 .cart-delivery .cart-delivery-controls .cart-edit,
.cart .cart-row .cart-col-1 .cart-delivery .cart-delivery-controls .cart-change {
    background-color: transparent;
    color: #000000;
    border: none;
    margin-left: 6px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-img-container .cart-item-img {
    width: 100%;
    height: auto;
    padding: 20px;
    cursor: pointer;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-text-h {
    margin-top: 10px;
    font-size: 24px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-text-p {
    color: #737373;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-btn-promo {
    background-color: transparent;
    color: #737373;
    font-size: 14px;
    text-decoration: underline;
    border: none;
    padding: 0;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price {
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price .cart-item-og-price {
    text-decoration: line-through;
    color: #747474;
    margin-right: 8px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price .cart-item-sub {
    background-color: #3f3f3f;
    color: #ffffff;
    border: none;
    border-radius: 20px 0px 0px 20px;
    padding: 2px 20px;
    margin-left: 50px;
    margin-right: 15px;
}

.cart .cart-row .cart-col-1 .cart-items .cart-item .cart-item-details .cart-item-text .cart-item-price .cart-item-add {
    background-color: #3f3f3f;
    color: #ffffff;
    border: none;
    border-radius: 0px 20px 20px 0px;
    padding: 2px 20px;
    margin-left: 15px;
}

.cart .cart-row .cart-col-2 {
    font-family: "Inter", sans-serif;
    padding: 0px 40px;
}

.cart .cart-row .cart-col-2 .cart-delivery-text {
    font-size: 20px;
}

.cart .cart-row .cart-col-2 .cart-promo-text {
    font-size: 12px;
}

.cart .cart-row .cart-col-2 .cart-promo-box {
    border: 1px solid black;
    margin-bottom: 20px;
}

.cart .cart-row .cart-col-2 .cart-promo-btn {
    background-color: transparent;
    color: #000000;
    height: 100%;
    border: #000000 1px solid;
}

.cart .cart-row .cart-col-2 .cart-promo-box::placeholder {
    color: #8f8f8f;
}

.cart .cart-row .cart-col-2 .cart-price {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 25px;
}

.cart .cart-row .cart-col-2 .cart-price .cart-price-text {
    font-size: 20px;
    margin-bottom: 5px;
}

.cart .cart-row .cart-col-2 .cart-price .cart-price-right {
    float: right;
}

.cart .cart-row .cart-col-2 .cart-price .cart-btn {
    width: 100%;
    background-color: var(--black1);
    margin-top: 25px;
    border-radius: 4px;
    border: none;
}

.cart .cart-empty .cart-h {
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}

.cart .cart-empty .cart-empty-container {
    font-family: 'Cabin', sans-serif;
    background-color: #f6f6f6;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 100px 0px;
}

.cart .cart-empty .cart-empty-container .cart-empty-h {
    font-size: 24px;
    margin-top: 20px;
}

.cart .cart-empty .cart-empty-container .cart-empty-p {
    font-size: 16px;
}

.cart .cart-empty .cart-empty-container .cart-empty-btn {
    background-color: var(--black1);
    color: #ffffff;
    border-radius: 32px;
    margin-top: 10px;
    padding: 8px 35px;
    border: none;
}

.bold {
    font-weight: bolder;
}

.green {
    color: #6A9B00;
}

.custom-hr-1 {
    margin: 10px 0;
    border-top: #000000 1px solid;
    opacity: 1;
}

.cart-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust this value if necessary */
}

.change-form {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.deliver-to {}

.save-address {
    border-radius: 4px !important;
    background: #0C2D1A !important;
    display: flex !important;
    width: 120px;
    height: 32px;
    padding: 11px 0px 10px 0px !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 24px;
}

.multiple-addresses {
    border-bottom: 1px solid #737373;
    margin-bottom: 19px;
    padding-bottom: 19px;
}

.edit-your {
    color: #242424;
    font-family: Cabin;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 365px;
    display: flex;
    margin-top: 41px;
    margin-bottom: 19px;

}
.address-form-top{
    border-radius: 8px;
border: 1px solid #242424;
display: flex;
width: 100%;
padding: 54px 79px 54px 78px;
justify-content: center;
align-items: flex-start;
gap: 32px;
margin-bottom: 12px;
}
.form-bottom{
    border-radius: 8px;
    border: 1px solid  #242424;
    display: flex;
width: 100%;
padding: 54px 0px;
justify-content: center;
align-items: center;
gap: 8px;
}
.form-changes{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit-col{
    gap: 8px;
    display: flex;
    flex-direction: column;

}
.profile-input3{
    max-width: 423px !important;
    width: 100% !important;
}
.input-group-text{
    background-color: white !important;
    color:  #3F3F3F !important;
font-family: Cabin !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 22px !important; /* 169.231% */
}
.cart-item-text-promo{
overflow: hidden !important;
}
