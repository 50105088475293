.orders-page {
    padding: 20px;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .dashboard-controls .btn {
    margin-left: 10px;
  }
  
  .profile-menu {
    background-color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
  }
  
  .order-card {
    border: none;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }
  
  .order-card img {
    max-width: 100px;
    border-radius: 10px;
  }
  
  .price {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .discounted-price {
    text-decoration: line-through;
    color: #6c757d;
    margin-left: 10px;
  }
  
  .customer-info {
    color: #6c757d;
  }
  