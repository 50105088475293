.wishlist{
    padding: 80px;
}
.wishlist .wishlist-h{
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}
.wishlist .wishlist-items{
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 40px 240px;
    font-family: 'Cabin', sans-serif;
    margin-bottom: 20px;
}
.wishlist .wishlist-items .wishlist-item{
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 25px;
    font-family: 'Cabin', sans-serif;
    margin-bottom: 20px;
    position: relative;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-item-img-container .wishlist-item-img{
    width: 100%;
    height: auto;
    padding: 20px;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-item-text .wishlist-item-text-h{
    margin-top: 10px;
    font-size: 24px;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-item-text .wishlist-item-text-p{
    color: #737373;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-item-text .wishlist-item-btn-promo{
    background-color: transparent;
    color: #737373;
    font-size: 14px;
    text-decoration: underline;
    border: none;
    padding: 0;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-item-text .wishlist-item-price{
    font-size: 18px;
    font-family: "Inter", sans-serif;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-item-text .wishlist-item-price .wishlist-item-og-price{
    text-decoration: line-through;
    color: #747474;
    margin-right: 8px;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-btn-group{
    padding-top: 40px;
    text-align: center;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-btn-group .wishlist-btn-buy{
    width: 80%;
    margin: 5px;
    background-color: var(--black1);
    color: #ffffff;
    border: none;
    border-radius: 8px;
}
.wishlist .wishlist-items .wishlist-item .wishlist-item-details .wishlist-btn-group .wishlist-btn-cart{
    width: 80%;
    margin: 5px;
    border: #000000 2px solid;
    border-radius: 8px;
    background-color: transparent;
    color: #000000;
}

.wishlist .wishlist-empty .wishlist-h{
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
}
.wishlist .wishlist-empty .wishlist-empty-container{
    font-family: 'Cabin', sans-serif;
    background-color: #f6f6f6;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 100px 0px;
}
.wishlist .wishlist-empty .wishlist-empty-container .wishlist-empty-h{
    font-size: 24px;
    margin-top: 20px;
}
.wishlist .wishlist-empty .wishlist-empty-container .wishlist-empty-p{
    font-size: 16px;
}
.wishlist .wishlist-empty .wishlist-empty-container .wishlist-empty-btn{
    background-color: var(--black1);
    color: #ffffff;
    border-radius: 32px;
    margin-top: 10px;
    padding: 8px 35px;
    border: none;
}
.wishlist-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust this value if necessary */
  }
  .wishlist-item-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: auto !important;
  }
  .wishlist-item{
  }