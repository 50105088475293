.home .home-top {
    height: 70vh;
    border-bottom: var(--black2) 5px solid;
    position: relative;
}

.home .home-top .home-color {
    background-color: var(--pastel-purple);
    border-right: var(--black2) 4px solid;
}

.home .home-top .home-white {
    background-color: var(--white1);
}

.home .home-top .home-white .home-txt-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 48px;
    color: var(--black1);
    margin-top: 100px;
    margin-left: 250px;
    width: 60%;
}

.home .home-top .home-white .home-txt-h .home-txt-fm {
    color: #9C7FFF;
}

.home .home-top .home-white .home-txt {
    margin-left: 250px;
    width: 60%;
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    color: black;
}

.home .home-top .home-card {
    margin-left: 180px;
    margin-top: 100px;
    position: absolute;
    width: 20%;
    padding: 0;
    border-radius: 150px;
    font-family: 'Cabin', sans-serif;
}

.home .home-top .home-card .home-img {
    border-radius: 150px;
    border: solid var(--black2) 3px;
    height: 307px;
    object-fit: cover;
}

.home .home-top .home-card:hover .home-img {
    filter: brightness(40%);
}

.home .home-top .home-card .home-overlay {
    top: 35%;
    text-align: center;
    visibility: hidden;
    color: #ffffff;
}

.home .home-top .home-card:hover .home-overlay {
    visibility: visible;
}

.home .home-top .home-card .home-overlay .home-card-title {
    font-size: 25px;
}

.home .home-top .home-card .home-overlay .home-card-text {
    margin-top: -10px;
    font-weight: 100;
    font-size: small;
}

.home .home-brands {
    border-bottom: var(--black2) 5px solid;
    height: 75vh;
}

.home .home-brands .home-brands-content {
    margin-top: 100px;
    padding-left: 150px;
    background-color: var(--white1);
}

.home .home-brands .home-brands-color {
    border-left: var(--black2) 4px solid;
    background-color: var(--pastel-teal);
}

.home .home-brands .home-brands-content .home-brands-head {
    margin-bottom: 50px;
}

.home .home-brands .home-brands-content .home-brands-head .home-brands-txt .home-brands-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    color: var(--black1);
    width: 50%;
}

.home .home-brands .home-brands-content .home-brands-head .home-brands-txt .home-brands-p {
    width: 50%;
    color: black;
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}

.home .home-brands .home-brands-content .home-brands-head .home-brands-btn {
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
}

.home .home-brands .home-brands-content .home-brands-pl-row {
    margin-right: 150px;
    margin-bottom: 20px;
}

.home .home-brands .home-brands-content .home-brands-pl-row .home-brands-pl-col .home-brands-pl {
    height: 90px;
    border-radius: 10px;
}
.brands-img{
    width: 100%;
    object-fit: contain;
}
.home .home-brands-animate {
    border-left: var(--black2) 4px solid;
    border-bottom: var(--black2) 5px solid;
    height: 75vh;
    background-color: var(--pastel-teal);
    padding-top: 80px;
    padding-bottom: 50px;
}

.home .home-brands-animate .home-brands-a-pl-row {
    width: 75%;
    margin-bottom: 20px;
}

.home .home-brands-animate .home-brands-a-pl-row .home-brands-a-pl {
    height: 90px;
    border-radius: 10px;
    color: #ffffff;
}

.home .home-brands-animate .home-brands-a-btn {
    margin-top: 10px;
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
}

.home .home-creators {
    margin-top: 100px;
    margin-bottom: 100px;
}

.home .home-creators .home-creators-head {
    margin-bottom: 50px;
    padding-left: 150px;
    padding-right: 150px;
}

.home .home-creators .home-creators-head .home-creators-ctrl {
    align-items: center;
}

.home .home-creators .home-creators-head .home-creators-ctrl .custom-left-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 15px;
    color: #000000;
}

.home .home-creators .home-creators-head .home-creators-ctrl .custom-right-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-left: 15px;
    color: #000000;
}

.home .home-creators .home-creators-head .home-creators-txt .home-creators-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    color: var(--black1);
    width: 50%;
}

.home .home-creators .home-creators-head .home-creators-txt .home-creators-p {
    width: 50%;
    color: black;
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}

.home .home-creators .home-creators-card-wrapper {
    position: relative;
}

.home .home-creators .home-creators-card-wrapper .home-creators-card-bg {
    background-color: #F4ECC8;
    height: 250px;
    width: 100%;
    position: absolute;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards {
    margin-left: 150px;
    margin-right: 150px;
    padding-top: 50px;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards .home-creators-card {
    position: relative;
    border: none;
    border-radius: 0%;
    background-color: transparent;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards .home-creators-card .home-creators-card-img {
    border: none;
    border-radius: 0%;
    width: 250px;
    margin-bottom: 20px;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards .home-creators-card .home-creators-card-txt {
    top: 65%;
    font-family: 'Cabin', sans-serif;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards .home-creators-card .home-creators-card-txt .home-creators-card-h {
    font-size: large;
    margin-bottom: -5px;
    font-weight: bolder;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards .home-creators-card .home-creators-card-txt .home-creators-card-p {
    font-size: medium;
}

.home .home-creators .home-creators-card-wrapper .home-creators-cards .home-creators-card .home-creators-card-stats {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: black;
    padding: 20px;
    border: white solid 2px;
    border-radius: 100px;
}
.home-creators-card-img{
    height: 250px;
    object-fit: cover;

}
.product-card-img{
    max-width: 285px;
    height: 316px;
    width: 100%;
    object-fit: cover;
}

.home .home-discover {
    background: url(../../media/discover.png);
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #ffffff;
}

.home .home-discover .home-discover-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 48px;
}

.home .home-discover .home-discover-p {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
}

.home .home-discover .home-discover-btn {
    background-color: var(--white1);
    color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 12px 20px 12px 20px;
    border-radius: 8px;
    border: none;
    margin: 20px;
}

.home .home-products .home-products-row {
    background-color: var(--pastel-blue);
    margin-top: 80px;
    margin-bottom: 80px;
}

.home .home-products .home-products-row .home-products-content {
    color: #000000;
    padding-left: 150px;
    padding-right: 50px;
}

.home .home-products .home-products-row .home-products-content .home-products-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: -5px;
}

.home .home-products .home-products-row .home-products-content .home-products-p {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
}

.home .home-products .home-products-row .home-products-content .home-products-ctrl .custom-left-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 15px;
    color: #000000;
}

.home .home-products .home-products-row .home-products-content .home-products-ctrl .custom-right-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-left: 15px;
    color: #000000;
}

.home .home-products .home-products-row .home-products-content .home-products-btn {
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
    margin-top: 100px;
    margin-bottom: 50px;
}

.home .home-products .home-products-row .home-products-carousel-col .home-products-carousel .home-products-card {
    background-color: transparent;
    border: none;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 50px;
    font-family: 'Cabin', sans-serif;
}

.home .home-products .home-products-row .home-products-carousel-col .home-products-carousel .home-products-card .home-products-price {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: -5px;
    font-weight: 700;
}

.home .home-products .home-products-row .home-products-carousel-col .home-products-carousel .home-products-card .home-products-title {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
}

.home .home-products .home-products-row .home-products-carousel-col .home-products-carousel .home-products-card .home-products-desc {
    white-space: nowrap;
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    font-size: 13px;
}

.home .home-values .home-values-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    margin-bottom: 5px;
}

.home .home-values .home-values-p {
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    margin-bottom: 80px;
    width: 30%;
}

.home .home-values .home-values-content {
    padding-left: 150px;
    padding-right: 150px;
    margin-bottom: 100px;
}

.home .home-values .home-values-content .home-values-col .home-values-col-h {
    font-family: 'Cabin', sans-serif;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
}

.home .home-values .home-values-content .home-values-col .home-values-col-p {
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    margin-top: -5px;
}

.home .home-values .home-values-content .home-values-col .home-values-btn {
    background-color: transparent;
    border: none;
    color: #000000;
    font-weight: 700;
    font-family: 'Cabin', sans-serif;
}

.spinner-crea{
    position: relative;
    display: flex;
align-items: center;
justify-content: center;

}
.spinner-creator{

}
.wishlist-loading-product{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
}