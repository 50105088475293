.notifications{
    padding-top: 40px;
    padding-bottom: 60px;
    min-height: 60vh;
}
.notifications .notifications-h{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    text-align: center;
    padding-bottom: 40px;
}
.notifications .notification{
    margin: 5px 100px;
    border: #000000 solid 1px;
    border-radius: 10px;
    padding: 20px 35px;
    font-family: 'Cabin', sans-serif;
}
.notifications .notification .notification-h{
    font-size: 20px;
}
.notifications .notification .notification-p{
    font-size: 16px;
    color: #737373;
}

.new{
    background-color: #fcfcfc;
}
.opened{
    background-color: #efefef;
}

.notification.new {
    background-color: #fcfcfc;
  }
  
  .notification.opened {
    background-color: #efefef;
  }