.auth .auth-header {
    background-color: var(--white1);
    border-bottom: 2px black solid;
    padding-top: 10px;
    padding-bottom: 10px;
}

.auth .auth-header .auth-header-content .brand-name {
    margin-left: 70px;
    font-family: 'Russo One', sans-serif;
    font-size: xx-large;
    color: var(--btn-color);
}

.auth .auth-content {
    background: url(../../media/auth.png) ;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}
.bauth .bauth-content {
    background: url(../../media/bauth.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 80vh;
}
.cauth .cauth-content {
    background: url(../../media/cauth.png) !important;
    background-repeat: no-repeat;
    background-size: cover !important;
    height: 80vh !important;
}

.auth .auth-content .auth-txt{
    margin: 200px 100px;
    background-color: var(--white1);
    border: solid 1px #000000;
    padding: 20px;
}

.auth .auth-content .auth-txt .auth-h{
    font-family: 'Russo One', sans-serif;
    font-size: xx-large;
}
.auth .auth-content .auth-txt .auth-p{
    font-family: 'Cabin', sans-serif;
}

.auth .auth-content .auth-form{
    font-family: 'Cabin', sans-serif;
    border: #000000 solid 2px;
    border-radius: 10px;
    padding: 70px 100px;
    width: 80%;
    margin-top: 100px;
}
.auth .auth-content .auth-form .auth-form-txt{
    text-align: center;
    margin-bottom: 20px;
}
.auth .auth-content .auth-form .row .col .auth-otp{
    border: #000000 solid 1px;
    border-radius: 8px;
    padding: 15px;
}
.auth .auth-content .auth-form .auth-btn{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #000000 !important;
    border: none !important;
}
.auth-btn{
    color: #fff !important;
    justify-content: center;
}
.auth-btn div{
    background-color: transparent !important;
    padding: 0 !important;
}
.auth .auth-content .auth-form .input-grp{
    background-color: transparent;
    border: #000000 solid 1px;
    border-radius: 8px;
}
.auth .auth-content .auth-form .input-grp button{
    background-color: #000000;
    border: none;
}
.auth .auth-content .auth-form .auth-fp{
    display: block;
    margin-top: -5px;
    color: #000000;
    text-decoration: none;
}
.auth .auth-content .auth-form .auth-si-btn-wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.auth .auth-content .auth-form .auth-si-btn{
    background-color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 5px 32px 5px 32px;
    border-radius: 32px;
    border: none;
    text-align: center;
}
.auth .auth-content .auth-comment{
    font-family: 'Cabin', sans-serif;
    width: 80%;
    text-align: center;
}

.auth .auth-footer{
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: var(--white1);
    background-color: var(--black1);
}

.auth .auth-footer .auth-footer-right{
    text-align: right;
}

.auth .auth-footer .auth-footer-btn{
    background-color: transparent;
    border: none;
    color: var(--white1);
    font-family: 'Cabin', sans-serif;
    padding: 0px 10px 0px 0px;
    font-size: 16px;
}
.error-message {
    color: red;
    margin-top: 0px;
}
