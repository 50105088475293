.creators .creators-top{
    border-bottom: solid #000000 3px;
}

.creators .creators-top .creators-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: var(--black1);
    margin-top: 50px;
    margin-bottom: 20px;
    padding-left: 100px;
}

.creators .creators-top .creators-color {
    border-left: solid black 1px;
    background-color: var(--pastel-teal);
}

.creators .creators-head {
    padding-top: 60px;
    margin-bottom: 50px;
    padding-left: 150px;
    padding-right: 150px;
}

.creators .creators-head .creators-ctrl {
    align-items: center;
}

.creators .creators-head .creators-ctrl .custom-left-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 15px;
    color: #000000;
}

.creators .creators-head .creators-ctrl .custom-right-arrow {
    background-color: transparent;
    border: black solid 3px;
    padding: 5px 15px;
    border-radius: 50px;
    margin-left: 15px;
    color: #000000;
}

.creators .creators-head .creators-txt .creators-head-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    color: var(--black1);
    width: 50%;
}

.creators .creators-head .creators-txt .creators-head-p {
    width: 50%;
    color: black;
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
}

.creators .creators-card-wrapper {
    position: relative;
    margin-bottom: 50px;
}

.creators .creators-card-wrapper .creators-card-bg {
    height: 250px;
    width: 100%;
    position: absolute;
}

.bg-yellow{
    background-color: #F4ECC8;
}

.bg-green{
    background-color: #DEF4C8;
}

.bg-purple{
    background-color: #DBD1FC;
}

.bg-blue{
    background-color: #D8EDF9;
}

.creators .creators-card-wrapper .creators-cards {
    margin-left: 150px;
    margin-right: 150px;
    padding-top: 50px;
}

.creators .creators-card-wrapper .creators-cards .creators-card {
    position: relative;
    border: none;
    border-radius: 0%;
    background-color: transparent;
    margin: 2px;
}

.creators .creators-card-wrapper .creators-cards .creators-card .creators-card-img {
    border: none;
    border-radius: 0%;
    width: 250px;
    margin-bottom: 20px;
    height: 250px;
object-fit: cover;
}

.creators .creators-card-wrapper .creators-cards .creators-card .creators-card-txt {
    top: 65%;
    font-family: 'Cabin', sans-serif;
}

.creators .creators-card-wrapper .creators-cards .creators-card .creators-card-txt .creators-card-h {
    font-size: large;
    margin-bottom: -5px;
    font-weight: bolder;
}

.creators .creators-card-wrapper .creators-cards .creators-card .creators-card-txt .creators-card-p {
    font-size: medium;
}

.creators .creators-card-wrapper .creators-cards .creators-card .creators-card-stats {
    position: absolute;
    bottom: 0px;
    right: 29px;
    background-color: black;
    padding: 20px;
    border: white solid 2px;
    border-radius: 100px;
    color:  #FEFEFE;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 75px;
    height: 75px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creators .creators-discover {
    background: url(../../media/discover1.png);
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #ffffff;
}

.creators .creators-discover .creators-discover-h {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 48px;
}

.creators .creators-discover .creators-discover-p {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
}

.creators .creators-discover .creators-discover-btn {
    background-color: var(--white1);
    color: var(--black1);
    font-family: 'Cabin', sans-serif;
    padding: 12px 20px 12px 20px;
    border-radius: 8px;
    border: none;
    margin: 20px;
}