.product{
    position: relative;
}

.product .product-background-semicircle{
    position: absolute;
    z-index: -1;
    width: 600px;
    height: 600px;
    border-radius: 300px;
    background-color: var(--pastel-green) !important;
    top: 60px;
    left: -150px;
}

.product .product-details .product-img{
    padding-top: 150px;
    text-align: center;
}

.product .product-details .product-slider-thumbnails{
    padding-top: 150px;
}

.product .product-details .product-img .product-img-active{
    width: 430px;
    height: 430px;
    object-fit: cover;

}

.product .product-details .product-slider-thumbnails .product-slider-thumbnail{
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.product .product-details .product-slider-thumbnails .pst{
    margin-bottom: 10px;
}

.product .product-details .product-info{
    padding: 90px 150px 70px 70px;
    font-family: 'Cabin', sans-serif;
}

.product .product-details .product-info .product-h{
    font-size: 24px;
    font-weight: 700;
}

.product .product-details .product-info .product-subh{
    font-size: 16px;
    font-weight: 400;
}

.product .product-details .product-info .product-features{
    font-size: 16px;
}

.product .product-details .product-info .product-features .product-feat{
    display: flex;
    margin-right: 50px;
    cursor: pointer;
    text-decoration: none;
    color: #000000;
}

.product .product-details .product-info .product-quantity{
    margin-top: 25px;
    margin-bottom: 25px;
    align-items: center;
}

.product .product-details .product-info .product-quantity .product-price{
    font-size: 16px;
}

.product .product-details .product-info .product-quantity .product-price .product-p-original{
    text-decoration: line-through;
    color: gray;
}

.product .product-details .product-info .product-quantity .product-p-derived{
    font-size: 24px;
    font-weight: 700;
    margin-left: 10px;
}

.product .product-details .product-info .product-quantity .product-btn-sub{
    padding: 0px 20px;
    border: none;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 24px;
    color: #ffffff;
    background-color: #000000;
}

.product .product-details .product-info .product-quantity  .product-number{
    font-size: 24px;
}

.product .product-details .product-info .product-quantity .product-btn-add{
    padding: 0px 20px;
    border: none;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 24px;
    color: #ffffff;
    background-color: #000000;
}

.product .product-details .product-info .product-btns{
    margin-bottom: 20px;
}

.product .product-details .product-info .product-btns .product-buy{
    padding: 12px 20px;
    border-radius: 8px;
    background-color: var(--black1);
    color: #ffffff;
    border: none;
}

.product .product-details .product-info .product-btns .product-cart{
    padding: 12px 20px;
    border-radius: 8px;
    background-color: #ffffff;
    border: #000000 solid 2px;
    color: #000000;
    margin-left: 5px;
}

.product .product-details .product-info .product-btns .product-oos{
    padding: 12px 20px;
    border-radius: 8px;
    background-color: #626262;
    color: #ffffff;
    border: none;
}

.product .product-details .product-info .product-btns .product-notify{
    color: #B88D00;
    margin-left: 10px;
}

.product .product-details .product-info .product-wishlist{
    border: none;
    background-color: transparent !important;
    color: #000000 !important;
    font-size: 14px;
    padding: 0px;
    margin-bottom: 20px;
}

.product .product-details .product-info .product-net-quantities .product-net-quantity{
    padding: 5px 32px;
    border-radius: 32px;
    border: #000000 solid 1px;
    background-color: transparent;
    color: #000000;
    margin-top: 10px;
    margin-right: 5px;
}

.product .product-details .product-info .product-description{
    margin-top: 20px;
}

.product .product-details .product-info .product-description .product-desc-h{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.inline{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.product-wishlist.active {
    background-color: pink  !important;
    color: white !important;
  }
  
  .product-wishlist {
    background-color: white !important;
    color: black !important;
  }

  
  .product-slider-thumbnail {
    cursor: pointer !important;
    margin-bottom: 10px !important;
    width: 100% !important;
    height: auto !important;
  }
  
.heart.active path{
    fill: red;
}
  .product-net-quantity.selected{
background-color: #000000 !important;
color: white !important;
  }
  .size-chart{
    color: var(--Yellow, #B88D00);
leading-trim: both;
text-edge: cap;
font-family: Cabin;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
display: flex;
width: 167px;
height: 25px;
flex-direction: column;
justify-content: center;
  }