@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Inter&family=Plus+Jakarta+Sans:wght@700&family=Russo+One&display=swap');

.App {
  overflow-x: hidden;
}

body {
  background-color: var(--white1) !important;
}

:root {
  --black1: #0C2D1A;
  --black2: #242424;
  --gray1: #3F3F3F;
  --gray2: #737373;
  --white1: #FEFEFE;
  --pastel-purple: #DBD1FC;
  --pastel-yellow: #F4ECC8;
  --pastel-green: #DEF4C8;
  --pastel-parrot: #EEF8E4;
  --pastel-blue: #D8EDF9;
  --pastel-teal: #BDDAD0;
  --disabled: #737373;
  --btn-color: #0D4224;
}

.header {
  background-color: var(--white1) !important;
  border-bottom: 2px black solid !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.header .header-content {
  align-items: center;
}

.header .header-content .brand-name {
  font-family: 'Russo One', sans-serif;
  font-size: xx-large;
  color: var(--btn-color);
}

.header .header-content .header-search {
  width: auto;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 20px;
  background-color: transparent;
  border: black solid 1px;
  height: 35px;
}

.header .header-content .header-search .header-search-input-box {
  height: 32px;
  font-size: small;
  background-color: transparent;
  border: none;
}

.header .header-content .header-search .header-search-button {
  height: 30px;
  background-color: transparent;
  border: none;
  padding: 0;
}

.header .header-content .header-search .header-search-button .header-search-logo {
  background-color: black;
  padding: 6px;
  border: black solid 1px;
  border-radius: 40px;
  margin: 4px;
  color: #ffffff;
}

.header .header-content .header-btn {
  font-family: 'Cabin', sans-serif;
  background-color: transparent;
  border: none;
  color: var(--black2);
  font-size: large;
}

.header .header-content .btn1 {
  margin-left: 5px;
  margin-right: 5px;
}

.header .header-content .btn2 {
  margin-left: 5px;
  margin-right: 40px;
}

.header .header-content .btn3 {
  margin-left: 3px;
  margin-right: 3px;
}

.header .header-content .header-dd {
  display: inline;
}

.header-dd-alert {
  width: 30px;
  height: 30px;
  background-color: #ff0000;
}

.header .header-content .header-dd .header-dd-content {
  padding: 15px;
  font-size: large;
  width: 380px;
}

.header .header-content .header-dd .header-dd-content .header-dd-btn {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-wrap: pretty;
}

.header .header-content .header-dd .header-dd-content .header-dd-all {
  background-color: var(--black1);
  font-family: 'Cabin', sans-serif;
  padding: 5px 32px 5px 32px;
  border-radius: 32px;
  border: none;
  float: right;
}

.header .header-content .header-dd .header-dd-content .header-dd-btn .header-dd-txt {
  font-size: medium;
  color: var(--gray2);
}

.header .header-content .header-dd1 {
  display: inline;
  float: right;
}

.header .header-content .header-dd1 .header-dd1-content {
  padding: 15px;
  font-size: large;
  width: 200px;
}

.header .header-content .header-dd1 .header-dd1-content .header-dd1-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

.header .header-content .header-dd1 .header-dd1-content .header-dd1-btn .header-dd1-icon {
  float: right;
  margin-top: 5px;
  rotate: 45deg;
}

.header .header-content .profile .header-profile-img {
  margin-right: 5px;
  width: 28px;
  height: auto;
  clip-path: circle(50% at 50% 50%);
  text-transform: uppercase;
}

.icon-select {
  margin-left: 5px;
}

.header-modal-h {
  border: none;
}

.header-modal-content {
  background-color: var(--white1);
  padding: 30px 100px;
  border-radius: 15px;
  border: none;
}

.modal-closebtn {
  justify-content: end;
}

.header-modal-btn {
  width: 100%;
  height: 15vh;
  border-radius: 15px;
  font-size: 28px;
  font-family: 'Cabin', sans-serif;
  background-color: transparent;
}

.header-modal-btn:hover {
  filter: brightness(70%);
}

.htb-1 {
  background: url(./media/c1.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-2 {
  background: url(./media/c2.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-3 {
  background: url(./media/c3.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-4 {
  background: url(./media/c4.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-5 {
  background: url(./media/c5.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-6 {
  background: url(./media/c6.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-7 {
  background: url(./media/c7.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.htb-8 {
  background: url(./media/c8.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}

.dropdown-toggle::after {
  display: none;
}

.footer {
  padding-left: 100px !important;
  padding-right: 100px !important;
  padding-top: 50px !important;
  padding-bottom: 80px !important;
  color: var(--white1) !important;
  background-color: var(--black1) !important;
}

.footer .footer-content .footer-h {
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
}

.footer .footer-btn {
  background-color: transparent;
  border: none;
  color: var(--white1);
  font-family: 'Cabin', sans-serif;
  padding: 0px 10px 0px 0px;
  font-size: 16px;
}

.footer .footer-content .footer-socials {
  margin-top: 10px;
}

.footer .footer-content .brand-name {
  font-family: 'Russo One', sans-serif;
  font-size: xx-large;
  color: var(--white1);
  float: right;
  top: 35%;
  position: relative;
}
.sign-in{
  display: flex;
justify-content: flex-end;
align-items: center;
gap: 8px;
cursor: pointer;
}
.sign-icon{
  background-color:  #242424;
  stroke-width: 1px;
  stroke:  #242424;
  width: 32px;
height: 32px;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
}
.sign-icon img{

}
.sign-text{
  color:  #242424;
  font-family: Cabin;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
width: 57px;
height: 11px;
flex-direction: column;
justify-content: center;
}
.header-dd .dropdown-toggle::after {
  display: none;
}
.header-btn.profile::after {
  display: none;
}
.dropdown-open{
  color: rgba(184, 141, 0, 1) !important;
}
.badge-cart{
  background-color: #C92E2E !important;
  width: 17px !important;
height: 17px !important;
flex-shrink: 0;
border-radius: 50% !important;
position: relative !important;
display: inline-table !important;
top: -12px !important;
left: -8px !important;
color: #FFF !important;
font-family: Cabin !important;
font-size: 10px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
}
.custom-navbar{
  justify-content: space-between !important;
}