.add-address{
    width: 100%;
    max-width: 1120px;
}
.address-profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}